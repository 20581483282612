/*menu responsive toggle */
jQuery("#menu-toggle").click(function(e) {
  e.preventDefault();
  const newLocal = "#wrapper";
  jQuery(newLocal).toggleClass("toggled");
});




! function($) {
  "use strict";
  var a = {
      accordionOn: ["xs"]
  };
  $.fn.responsiveTabs = function(e) {
      var t = $.extend({}, a, e),
          s = "";
      return $.each(t.accordionOn, function(a, e) {
          s += " accordion-" + e
      }), this.each(function() {
          var a = $(this),
              e = a.find("> li > a"),
              t = $(e.first().attr("href")).parent(".tab-content"),
              i = t.children(".tab-pane");
          a.add(t).wrapAll('<div class="responsive-tabs-container" />');
          var n = a.parent(".responsive-tabs-container");
          n.addClass(s), e.each(function(a) {
              var t = $(this),
                  s = t.attr("href"),
                  i = "",
                  n = "",
                  r = "";
              t.parent("li").hasClass("active") && (i = " active"), 0 === a && (n = " first"), a === e.length - 1 && (r = " last"), t.clone(!1).addClass("accordion-link" + i + n + r).insertBefore(s)
          });
          var r = t.children(".accordion-link");
          e.on("click", function(a) {
              a.preventDefault();
              var e = $(this),
                  s = e.parent("li"),
                  n = s.siblings("li"),
                  c = e.attr("href"),
                  l = t.children('a[href="' + c + '"]');
              s.hasClass("active") || (s.addClass("active"), n.removeClass("active"), i.removeClass("active"), $(c).addClass("active"), r.removeClass("active"), l.addClass("active"))
          }), r.on("click", function(t) {
              t.preventDefault();
              var s = $(this),
                  n = s.attr("href"),
                  c = a.find('li > a[href="' + n + '"]').parent("li");
              s.hasClass("active") || (r.removeClass("active"), s.addClass("active"), i.removeClass("active"), $(n).addClass("active"), e.parent("li").removeClass("active"), c.addClass("active"))
          })
      })
  }
}(jQuery);


$('.responsive-tabs').responsiveTabs({
                       accordionOn: ['xs', 'sm']
                });



                $(document).ready(function() {
                    $('input[type="file"]').on("change", function() {
                      let filenames = [];
                      let files = document.getElementById("customFile").files;
                      if (files.length > 1) {
                        filenames.push("Total Files (" + files.length + ")");
                      } else {
                        for (let i in files) {
                          if (files.hasOwnProperty(i)) {
                            filenames.push(files[i].name);
                          }
                        }
                      }
                      $(this)
                        .next(".custom-file-label")
                        .html(filenames.join(","));
                    });
                  });
                  



/* otp active button*/
document.querySelectorAll('input').forEach((input) => {
  input.addEventListener('blur', function() {
    this.classList.toggle('orange', this.value.length > 0);
  });
});

function manage(txt) {
  var bt = document.getElementById('btSubmit');
  if (txt.value != '') {
      bt.disabled = false;
  }
  else {
      bt.disabled = true;
  }
}    



